import { observable, action, makeObservable } from 'mobx';

class LocationStore {
  location = null;

  updateLocation(location) {
    this.location = location
  }

  getLocation() {
    return this.location
  }

  activeDriver = null;
  lastLocation = null;

  constructor(api) {
    makeObservable(this, {
      location: observable,
      updateLocation: action,
      getLocation: action,
      activeDriver: observable,
      lastLocation: observable,
      setDriver: action,
      loadLocation: action
    });

    this.api = api;
  }

  setDriver(id) {
    if (id === this.activeDriver) return;

    this.activeDriver = id
    this.lastLocation = null
    if (this.activeDriver) {
      this.loadLocation();
      if (!this.timer) {
        this.timer = setInterval(() => this.loadLocation(), 10000)
      }
    } else {
      if (this.timer)
        clearInterval(this.timer);
    }
  }

  loadLocation() {
    if (!this.activeDriver) {
      this.lastLocation = null;
      return;
    }
    this.api.get(`/drivers/${this.activeDriver}/last-known-location?past=600000`).then((r) => {
      if (r.status == 200) {
        if (r.data.driver_id === parseInt(this.activeDriver))
          this.lastLocation = r.data
      }
    })  
  }
}

export default LocationStore
