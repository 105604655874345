import moment from 'moment-timezone';

export const goToAssignment = (id) => {
  window.open(`/assignments/${id}`, '_blank');
};

export const goToShipment = (id) => {
  window.open(`/shipments/${id}`, '_blank');
};
export const getGoogleMapLink = (shipment) => {
  const url = 'https://google.com/maps/?layer=t&q=';

  const query = shipment.dropoff_uncharted
    ? `${shipment.dropoff_address.lat},${shipment.dropoff_address.lng}`
    : `${shipment.dropoff_address.street} ${shipment.dropoff_address.city} ${shipment.dropoff_address.state} ${shipment.dropoff_address.zipcode}`;
  return url + query;
};

export const getGoogleMapStreetLink = (shipment) => {
  return `https://google.com/maps/?q=&layer=c&cbll=` + `${shipment.dropoff_address.lat},${shipment.dropoff_address.lng}`;
};

export const convertLogToMessage = (messages) => {
  if (!Array.isArray(messages)) return [];

  const converted = [];

  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    const nextMessage = messages[i + 1];

    const isInsertTime = !!nextMessage && (Date.parse(new Date(nextMessage.ts)) - Date.parse(new Date(message.ts)) >= 1800000);
    const isSameDay = !!nextMessage && moment().isSame(moment(nextMessage.ts), 'day');

    const objMerge = {
      id: message.ts,
      body: isSameDay ? moment(nextMessage.ts).format('[TODAY], hh:mmA') : moment(messages[i].ts).format('MM/DD/YYYY, hh:mmA'),
      ts: nextMessage?.ts ? nextMessage.ts : message.ts,
      ref_type: 'TIMELINE',
    };

    converted.push(message);
    if (isInsertTime) converted.push(objMerge);
  }

  return converted;
}
