import { observable, action, makeObservable } from 'mobx';

class EtaStore {
  constructor(api) {
    makeObservable(this, {
      eta: observable,
      assignmentId: observable,
      loading: observable,
      setAssignment: action,
      updateEta: action
    });

    this.api = api;
  }

  eta = null;
  assignmentId = null;
  loading = false;

  setAssignment(assignmentId) {
    this.eta = null
    this.loading = true
    this.assignmentId = assignmentId;
    this.api.get(`/assignments/${assignmentId}/eta`).then(action((response) => {
      this.loading = false;
      if (response.status === 200)
        this.eta = response.data;
    }));
  }

  updateEta(ts) {
    if (!this.assignmentId) return;
    return this.api.post(`/assignments/${this.assignmentId}/eta`, {ts}).then((response) => {
      if (response.status === 200) {
        this.eta = response.data;
        return response.data;
      } else {
          return null;
      }
    }) 
  }
}

export default EtaStore;
